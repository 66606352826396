import * as React from 'react'
import { Box } from '@chakra-ui/react'
import { CtaSimple } from '../components/ctaSimple/'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { NavBar } from '../components/navbar'
import fractalBg from '../assets/svg/info-illustrations/jagged-bg.png'
import { InlineWidget } from "react-calendly";

const ThanksPage = ({ location }) => {
  const queryParams = new URLSearchParams(location.search)
  let decodedName = ""
  let decodedEmail = ""
  
  if (typeof window !== "undefined") {
    decodedName = window.atob(queryParams.get('n'))
    decodedEmail = window.atob(queryParams.get('e'))
  }

  return (
    <Layout>
      <Seo title="Thanks" />
      <Box
        bgImage={fractalBg}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
      >
        <NavBar />
        <CtaSimple
          title={
            <>
              Thanks for reaching out!<br/>Book a quick intro call with us using the form below:
            </>
          }
          titleSize="lg"
          maxW="700px"
        />
      </Box>
      <Box
        as="section"
        maxW={{
          base: 'xl',
          md: '7xl',
        }}
        mx="auto"
        px={{
          base: 4,
          md: 8,
        }}
        py={{
          base: 10,
          md: 24,
        }}
      >
        <InlineWidget 
          url="https://calendly.com/carbonqa/intro-call"
          pageSettings={{
            backgroundColor: "8C0825",
            primaryColor: "FFFFFF",
            textColor: "F9F9F9",
            hideGdprBanner: true,
          }}
          styles={{
            height: '700px',
          }}
          prefill={{
            email: decodedEmail,
            name: decodedName,
          }}
        />
        <form style={{display:`none`}} name="recaptured-contact" data-netlify="true">
          <input type="hidden" name="form-name" value="recaptured-contact" />
          <input type="hidden" name="score" value="" />
          <input type="hidden" name="session" value="" />
          <input type="hidden" name="company" value="" />
          <input type="hidden" name="name" value="" />
          <input type="hidden" name="email" value="" />
          <input type="hidden" name="phone" value="" />
          <input type="hidden" name="needs" value="" />
          <input type="hidden" name="project" value="" />
        </form>
      </Box>
    </Layout>
  )
}

export default ThanksPage
